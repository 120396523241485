import { Loader, LoaderOptions } from 'google-maps'
document.addEventListener('DOMContentLoaded', init)

async function init() {
    const el = document.querySelector('.map')
    if (!el) return

    const options = {}
    const loader = new Loader('AIzaSyAr0UpaNZYmIJ1woxO5o2dSQlkE-Mrr1CE', options)

    const google = await loader.load();
    const position = {lat: 47.4940638, lng: 16.3585936}
    const map = new google.maps.Map(document.getElementById('map'), {
        zoom: 15,
        center: position,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false
    })

    const title = 'Your Company'
    const marker = new google.maps.Marker({
        position,
        map,
        title
    })
}
